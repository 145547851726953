<template>
  <div class="logger-list">
    <div class="top-filter-container">
      <div class="filter-col flex-col">
        <div class="filter-item icon-wrapper">
          <i class="far fa-server"></i>
        </div>
        <el-select
          v-model="project"
          class="filter-item big"
          @change="onFilter"
        >
          <el-option
            v-for="item in projectOptions"
            :key="item.key"
            :label="item.label"
            :value="item.key"
          />
        </el-select>
      </div>

      <div class="filter-col flex-col">
        <div class="filter-item icon-wrapper">
          <i class="far fa-calendar"></i>
        </div>
        <el-select
          v-model="timeRange"
          class="filter-item big"
          @change="onFilter"
        >
          <el-option
            v-for="item in timeRangeOptions"
            :key="item.key"
            :label="item.label"
            :value="item.key"
          />
        </el-select>
      </div>
    </div>

    <div class="app-container">
      <div class="filter-container filter-container--flex">
        <div class="filter-col flex-col">
          <el-input
            v-model="search"
            placeholder="Search"
            class="filter-item big"
            clearable
            @clear="onFilter"
            @keyup.enter.native="onFilter"
          />

          <div class="flex-mobile-container">
            <el-button
              class="filter-item"
              type="primary"
              icon="el-icon-search"
              :loading="listLoading"
              @click="onFilter"
            />
            <el-button
              class="filter-item"
              type="default"
              icon="el-icon-refresh-right"
              :loading="listLoading"
              @click="onSearchReset"
            />
          </div>
        </div>

        <div class="filter-col flex-col">
          <div class="filter-item">
            Level:
          </div>
          <el-select
            v-model="level"
            class="filter-item small"
            @change="onFilter"
          >
            <el-option
              v-for="item in levelOptions"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>

          <div class="filter-item filter-item--sort">
            Sort by:
          </div>
          <el-select
            v-model="sortBy"
            class="filter-item small"
            @change="handleSort"
          >
            <el-option
              v-for="item in sortingOptions"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            />
          </el-select>
        </div>
      </div>

      <el-table
        v-loading="listLoading"
        :data="list"
        border
      >
        <el-table-column
          label="Logs"
          data-label="Logs"
          prop="title"
        >
          <template slot-scope="scope">
            <div class="logger-list-first-line link-type truncate-text">
              <el-tooltip
                :content="'Log level: ' + scope.row.level"
                placement="right"
              >
                <span
                  class="logger-level"
                  :class="'logger-level__' + scope.row.level"
                ></span>
              </el-tooltip>

              <router-link
                class="logger-list-title"
                :to="{ name: 'ViewLog', params: { logGroupHash: scope.row.logGroupHash } }"
              >
                <el-tag
                  v-if="scope.row.data && scope.row.data.isFatal"
                  size="mini"
                  type="danger"
                  effect="plain"
                >
                  Fatal
                </el-tag>
                {{ scope.row.title }}
              </router-link>
            </div>

            <div class="logger-list-second-line">
              <div class="logger-list-log-details truncate-text">
                {{ getDetails(scope.row.data) }}
              </div>

              <div class="logger-list-info">
                <i class="fal fa-server"></i>
                <span class="logger-project">{{ scope.row.project }}</span>
                <i class="fal fa-clock"></i>
                <span class="logger-time">{{ scope.row.updatedAt | timeAgo }} ago  — {{ scope.row.createdAt | timeAgo }} old</span>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          label="Events"
          data-label="Events"
          prop="count"
          width="70"
        >
          <template slot-scope="scope">
            {{ scope.row.count }}
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-show="total > 0"
        :pager-count="5"
        class="pagination"
        background
        layout="total, prev, pager, next, sizes"
        :current-page.sync="listQuery.pageNumber"
        :page-size.sync="listQuery.pageSize"
        :total="total"
        @size-change="getList"
        @current-change="getList"
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import { listLogGroups, getProjects } from '@/api/logger';

  export default {
    name: 'ListGroups',
    data() {
      const pageSize = +localStorage.getItem('loggerPageSize');
      const loggerProject = localStorage.getItem('loggerProject');
      const loggerLevel = localStorage.getItem('loggerLevel');
      const timeRange = localStorage.getItem('loggerTimeRange');

      return {
        total: 0,
        list: [],
        listLoading: true,
        search: '',
        filter: {},
        defSortBy: 'updatedAt',
        level: loggerLevel || 'all',
        project: loggerProject || 'all',
        timeRange: timeRange || '14-d',
        timeRangeOptions: [
          { label: 'Last hour', key: '1-h' },
          { label: 'Last 24 hours', key: '24-h' },
          { label: 'Last 7 days', key: '7-d' },
          { label: 'Last 14 days', key: '14-d' },
          { label: 'Last 30 days', key: '30-d' },
          { label: 'Last 90 days', key: '90-d' },
        ],
        levelOptions: [
          { label: 'All', key: 'all' },
          { label: 'Debug', key: 'debug' },
          { label: 'Log', key: 'log' },
          { label: 'Info', key: 'info' },
          { label: 'Warn', key: 'warn' },
          { label: 'Error', key: 'error' },
        ],
        sortingOptions: [
          { label: 'Last Seen', key: 'updatedAt' },
          { label: 'First Seen', key: 'createdAt' },
          { label: 'Events', key: 'count' },
        ],
        listQuery: {
          pageNumber: 1,
          pageSize: pageSize || 20,
          sortBy: 'updatedAt',
          sortDir: 'desc',
        },
      };
    },
    computed: {
      projectOptions() {
        const options = [{ label: 'All', key: 'all' }];

        getProjects().forEach((val) => {
          options.push({ label: val, key: val });
        });

        return options;
      },
    },
    created() {
      this.getList();
    },
    activated() {
      this.getList(true);
    },
    methods: {
      async getList(refresh = false) {
        try {
          this.setFilter();

          this.listLoading = true;

          if (!refresh) {
            this.list = [];
            this.listQuery.pageNumber = 1;
          }

          const data = await listLogGroups(this.listQuery);
          const { items, pageInfo } = data.page;
          this.list = items;
          this.total = pageInfo.itemsAvailable;
          this.listLoading = false;

          localStorage.setItem('defPageSize', this.listQuery.pageSize);
        } catch (e) {
          this.listLoading = false;
        }
      },
      handleSort(val) {
        this.listQuery.sortBy = val;
        this.listQuery.sortDir = 'desc';

        this.getList();
      },
      getDetails(data) {
        let details = '';

        let filteredStack = [];

        if (data.stack) {
          filteredStack = data.stack.filter((item) => item.fileName);
        }

        if (filteredStack[0] && filteredStack[0].fileName) {
          const line = filteredStack[0];
          const functionName = line.functionName ? `in ${line.functionName}` : '';
          details = `${line.fileName} ${functionName} at line ${line.lineNumber}:${line.columnNumber}`;
        } else if (Array.isArray(data) && data[1]) {
          details = JSON.stringify(data[1]);
        }

        return details;
      },
      onSearchReset() {
        this.search = '';
        this.getList();
      },
      setFilter() {
        this.filter = {};

        if (this.project !== 'all') {
          this.filter.project = this.project;
        }
        if (this.level !== 'all') {
          this.filter.level = this.level;
        }
        if (this.search.trim() !== '') {
          this.filter.search = this.search;
        }

        const [subtractAmount, subtractUnit] = this.timeRange.split('-');
        if (subtractAmount && subtractUnit) {
          this.filter.dateFrom = moment().subtract(subtractAmount, subtractUnit).toDate();
        }

        this.listQuery.filter = JSON.stringify(this.filter);
      },
      onFilter() {
        this.getList();

        localStorage.setItem('loggerProject', this.project);
        localStorage.setItem('loggerLevel', this.level);
        localStorage.setItem('loggerTimeRange', this.timeRange);
      },
    },
  };
</script>

<style lang="scss">
  @import '../../styles/views/_logger.scss';
</style>
